'use strict';



let commonZ = {

    // добавить данных по билету и дописать нужные флаги
    sussQRfromBase_infoline (current_scan_info, qr_event_data, mongerList, scan_statistics_local){

        console.log(current_scan_info, qr_event_data, mongerList, scan_statistics_local);

        // если флаг isToday, а он пока всегда стоит, когда мы вызываем эту функуцию
        current_scan_info.suss.show = qr_event_data.show;
        current_scan_info.suss.date = qr_event_data.date;

        // определяем продавца

        qr_event_data.data.forEach(base_qr=>{

            // совпадает кресло и платежка - или кресло и mo - в зависимости от продавца - надо
            // monger list подгружать и смотреть
            if ((current_scan_info.info.seat_id===base_qr.id)&&
                (current_scan_info.info.payment===base_qr.payment)){

                current_scan_info.g = true;
                current_scan_info.suss.sector = base_qr.sector;
                current_scan_info.suss.row    = base_qr.row;
                current_scan_info.suss.seat   = base_qr.chair;

                if (!base_qr.scanned){
                    scan_statistics_local.total.count++;

                    // приплюсовать в статистику к нужному продавцу
                    // топорно - надо сопоставлять с загруженным списком продавцов !!
                    if (current_scan_info.info.monger_mark=="IM"){
                        // так тоже нельзя - надо через find - певрым может быть любой
                        scan_statistics_local.event[0].count++;
                    }

                    if ((current_scan_info.info.monger_mark=="KSK")||(current_scan_info.info.monger_mark=="KSD")){
                        // так тоже нельзя - надо через find - певрым может быть любой
                        scan_statistics_local.event[1].count++;
                    }

                    if (current_scan_info.info.monger_mark=="USY"){
                        // так тоже нельзя - надо через find - певрым может быть любой
                        scan_statistics_local.event[2].count++;
                    }

                    if (current_scan_info.info.monger_mark=="UM"){
                        // так тоже нельзя - надо через find - певрым может быть любой
                        scan_statistics_local.event[3].count++;
                    }





                    base_qr.scanned = true;
                }
                else{
                    //console.log('Уже сканировали билет - подсветить его синим!');
                }



                //console.log('Билет правильный');
                //bb1 = true;
            }

        });





    }

}



export default commonZ
