import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },

  {
    path: '/scan',
    name: 'Scan',
    component: () => import('../views/scanner/Scan.vue')
  },

  {
    path: '/camera',
    name: 'Camera',
    component: () => import('../views/scanner/camera/Camera.vue')
  },

  {
    path: '/stat',
    name: 'Stat',
    component: () => import('../views/scanner/Statistics.vue')
  },

  {
    path: '/begin',
    name: 'Begin',
    component: () => import('../views/scanner/Begin.vue')
  },

  {
    path: '/active',
    name: 'Active',
    component: () => import('../views/scanner/Active.vue')
  },



]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
