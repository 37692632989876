import Vue from 'vue'
import Vuex from 'vuex'
import API_connector from "@/core/API_connector";

import commonZ from "@/core/common";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {

    api:new API_connector(),

    token:'',

    // api_json_event_map for kassa map
    eventMapKassa:{
      b:false,
      data:{}
    },

    // api_json_afisha - list - список акутальных мероприятий
    eventAfishaScan:{
      b:false,
      r:false,    // reloading
      data:{}
    },

    // api_json_event_scan data - мероприятие сейчас
    currentEventScan:{
      b:false,
      data:{
        event:{},
        layout:{},
        legend_group:{},
        price_map:{},
        prices:{},
        seats:{},
        sectors:{},
        show:{}
      },
      event:0
    },

    // список продавцов
    mongerList:{
      b:false,
      r:false,
      data:[
        {
          desc:'',
          id:0,
          mark: 'MRK',
          name: 'FIO',
          role_id:0,
          user_id:0
        }
      ]
    },

    // ready qr data from - а тут все места с qr на сегоднешнее мероприятие
    qr_event_data:{
      b:false,
      r:false,    // reloading
      event: 0,
      show: "[Не загружено]",
      date:"[нет данных]",
      time:"",
      age:"",

      data: []
    },

    // что было отсканировано за сегодня на этом устройстве
    scan_statistics_local:{

      event:[
        {
          order:      0,          // 1 инет, 2 касса, 3 уполномоч
          name:       "x",        // fio
          guess:       0,         // сколько продано
          mark:        "x",       // IM KS USH
          group_mark:  "xg",      // IM KS MGN
          count:       0,         // сколько уже отсканировали
          color:       "black"
        }
      ],
      error:[],
      total:{}

    },

    // то, что отсканировали и какой билет ищем
    current_scan_info:{
      b: false,       // распознали
      r: false,       // в процессе еще
      g: false,       // билет на тот спектакль?


      txt_scan: "",

      // статусы
      flag:{

        // обработка строки
        isRun:        false,
        isOurQR:      false,
        isParseOk:    false,
        isDoubleNow:  false,

        // добываем добавочную инфу
        isRunAdd:     false,
        isToday:      false,    // спектакль совпадает с подгруженным?
        inAfisha:     false,    // спектакль в актуальной афише? - а тут 62 выгружаются?!!!
        inBaseQR:     false,    // билет был заранее загружен в базу QR

      },

      // то что распознали из QR
      info:{
        event:0,

        monger_mark:"",
        serial:"",
        seat_id:0,
        paydoc:0,         // платежный документ mo или payment

      },

      // то что расшифровали и дописали из внутренних данных и вычислений
      suss:{

        show:         "[нет данных]",
        date:         "x",
        sector:       "x",
        row:          "x",
        seat:         "x",

        monger_name:  "x",
        monger:       0,
        payment:      0,
        mo:           0,

        group_mark:   "x",
        monger_mark:  "x",

      }
    },


  },
  mutations: {

    // распознаем что мы отсканировали и раздаем поля и флаги
    // никуда в базы не лезем, просто разбиваем отсканированную строку на поля
    // пишем данные в current_scan_info.info и current_scan_info.flag
    doScan_infoline_suss(state,  txt_scan){

      //txt_scan = '! IM 435-10416-3637-6827';
      //txt_scan = '! IM 1--6699-194'; // инет
      //txt_scan = '! IM 894--14639-11172'; // инет
      //txt_scan = '!  IM 416-10206-9187-10111'; // инет
      //console.log(txt_scan.charCodeAt(0));

      // уберем двойные пробелы внутр
      txt_scan = txt_scan.replace(/ {1,}/g," ");

      console.log(txt_scan, state.current_scan_info.txt_scan)

      // раздаем начальные значения
      state.current_scan_info.flag.isRun        = true;
      state.current_scan_info.flag.isParseOk    = false;
      state.current_scan_info.flag.isDoubleNow  = false;
      state.current_scan_info.flag.isOurQR      = false;

      // сканы разные - делаем (чтоб двойных не было)
      if (txt_scan !== state.current_scan_info.txt_scan) {

        state.current_scan_info.txt_scan = txt_scan;
        state.current_scan_info.b = false;
        state.current_scan_info.r = true;

        // мои сканы не более 32 символов
        if (txt_scan.length<33){

          // Первый знак это ! - code 33
          if (txt_scan.charCodeAt(0)===33){

            // теперь можно разворачивать чего у нас там в codeline
            state.current_scan_info.flag.isOurQR      = true;

            // пробуем распарсить значение внутри
            try {

              let r = txt_scan.split(' ');
              //console.log(r);
              state.current_scan_info.info.monger_mark = r['1'];

              let r1 = r['2'].split('-');
              //console.log(r1);
              state.current_scan_info.info.event    = parseInt(r1['0']);
              state.current_scan_info.info.serial   = parseInt(r1['1']);
              state.current_scan_info.info.seat_id  = parseInt(r1['2']);
              state.current_scan_info.info.paydoc   = parseInt(r1['3']);

              // все хорошо, считаем, что распарсили как надо
              state.current_scan_info.b = true;

              state.current_scan_info.flag.isRun        = false;
              state.current_scan_info.flag.isOurQR      = true;
              state.current_scan_info.flag.isParseOk    = true;
              state.current_scan_info.flag.isDoubleNow  = false;

              window.navigator.vibrate([100,30]);

            }
            catch (e){
              console.log('Что-то внутри не распарсилось =(');

              state.current_scan_info.flag.isRun        = false;
              state.current_scan_info.flag.isOurQR      = true;     // но считаем, что это наш QR "!"
              state.current_scan_info.flag.isParseOk    = false;
              state.current_scan_info.flag.isDoubleNow  = false;
            }


          }
          else{
            console.log('Первый символ не воскл. знак - мы так не кодируем наши билеты');

            state.current_scan_info.flag.isRun        = false;
            state.current_scan_info.flag.isOurQR      = false;
            state.current_scan_info.flag.isParseOk    = false;
            state.current_scan_info.flag.isDoubleNow  = false;
          }
        }
        else{
          console.log('Сканированная строка больше 32 символов');
          state.current_scan_info.flag.isRun        = false;
          state.current_scan_info.flag.isOurQR      = false;
          state.current_scan_info.flag.isParseOk    = false;
          state.current_scan_info.flag.isDoubleNow  = false;

        }


      }
      // вот только что такой же отсканировал - надо просигналить
      else {

        console.log('Только что вот такой же отсканировали');
        state.current_scan_info.flag.isRun        = false;
        state.current_scan_info.flag.isDoubleNow  = true;
        state.current_scan_info.flag.isParseOk    = true;
        state.current_scan_info.flag.isOurQR      = true;

        window.navigator.vibrate([100,30,100,30,100,200,200,30,200,30,200,200,100,30,100,30,100]);
      }



    },

    // отправить данные на сервер
    addScan(state){

      console.log(state.current_scan_info);

      let post_data = {};

      // распознали - даем полную инфу +-
      if (state.current_scan_info.g){
        post_data = {

          mode: "data",
          raw: state.current_scan_info.txt_scan,

          monger:     state.current_scan_info.info.monger_mark,
          event:      state.current_scan_info.info.event,
          seat:       state.current_scan_info.info.seat_id,
          document:   state.current_scan_info.info.paydoc,
        }
      }
      // непонятно что отсканили
      else{
        post_data = {
          mode: "raw",
          raw: state.current_scan_info.txt_scan,
        }
      }

      console.log(post_data);

      let t = state.api.addScan(post_data);

      console.log(t);

    },


    // должна быть загружена афиша                  [eventAfishaScan]
    // должен быть загружен сегодняшний спектакль   [currentEventScan]
    // должен быть сформирован пул правильных QR    [qr_event_data]
    doScan_infoline(state){

      console.log(state.current_scan_info);

      // додумать еще флагов

      //
      if (!state.current_scan_info.flag.isRun){

        state.current_scan_info.flag.isRunAdd   = true;

        state.current_scan_info.flag.isToday    = false;
        state.current_scan_info.flag.inAfisha   = false;

        // распарсили его норм
        if (state.current_scan_info.flag.isParseOk){

          // это наш сегодняшний
          if(state.currentEventScan.event===state.current_scan_info.info.event){

            state.current_scan_info.flag.isToday    = true;
            state.current_scan_info.flag.inAfisha   = true;

            // бежим по базе qr и ищем его там, добавляем в статистику и тп.
            commonZ.sussQRfromBase_infoline(
                state.current_scan_info,
                state.qr_event_data,
                state.mongerList.data,
                state.scan_statistics_local);

          }
          else{
            console.log("Спектакль-то не сегодня будет");
            state.current_scan_info.flag.isToday = false;

            // он вообще в афише, или прошел?
            // пробег по циклу с афишей
            state.eventAfishaScan.data.events.forEach(event =>{
              // нашли в афише
              if (event.id === state.current_scan_info.info.event){
                state.current_scan_info.flag.inAfisha   = true;
                state.current_scan_info.flag.isRunAdd   = false;

                state.current_scan_info.suss.show = event.show_name;
                state.current_scan_info.suss.date = event.timedate;
              }
            })

            // если не нашли его в афише - делаем запрос на сервер напрямую
            if (!state.current_scan_info.flag.inAfisha ){
              console.log('Не нашли в афише, делаем прямой запрос на сервер')
            }

          }



        }
        else{
          console.log('Чет билет не распарсился');

          if (state.current_scan_info.flag.isOurQR){
            console.log("Вроде наш - давай отправил напрямую запрос на сервер с этим QR, а там посмотрим")
          }
          else{
            console.log("Отсканили дичь какую-то, пулять никуда не буду, но в лог ошибок запишу")
          }
        }

      }
      else{
        console.log('По какой-то причине еще идет распознование, так что анализировать низя');

      }

      //console.log(state.current_scan_info.txt_scan);

      //   // прописать условия
      //   // если загружена база, то ищем в ней

      //
      //   // если нет базы - посылаем прямой запрос на север
      //
      //   // если и сети нет - ругаемся =(

      // ищем в загруженной базе
      //console.log(state.currentEventScan);
      //console.log(state.current_scan_info);
      //console.log(state.qr_event_data);
      //console.log(state.eventAfishaScan);


      // плохой билет - нигде не нашли
      state.current_scan_info.g = false;

      // смотрим вообще что это за спектакль - похож на наш?
      // это сегодня, значит ищем в базе qr
      if(state.currentEventScan.event===state.current_scan_info.info.event){

        console.log('event==');

        // не отсюда вытаскивать, а из qr_event_data
        // state.current_scan_info.suss.show = state.currentEventScan.data.show.name;
        // state.current_scan_info.suss.date = state.currentEventScan.dataevent.timedate;

        state.current_scan_info.suss.show = state.qr_event_data.show;
        state.current_scan_info.suss.date = state.qr_event_data.date;

        //console.log('Спектакль правильный');

        let bb1 = false;
        // ищем среди qr сегодняшнего спектакля
        state.qr_event_data.data.forEach(base_qr=>{
          //console.log(base_qr+' 7');

          // совпадает кресло и платежка - или кресло и mo - в зависимости от продавца - надо
          // monger list подгружать и смотреть
          if ((state.current_scan_info.info.seat_id==base_qr.id)&&
              (state.current_scan_info.info.paydoc==base_qr.payment)){

            state.current_scan_info.g = true;
            state.current_scan_info.suss.sector = base_qr.sector;
            state.current_scan_info.suss.row    = base_qr.row;
            state.current_scan_info.suss.seat   = base_qr.chair;

            if (!base_qr.scanned){
              state.scan_statistics_local.total.count++;

              // приплюсовать в статистику к нужному продавцу
              // топорно - надо сопоставлять с загруженным списком продавцов !!
              if (state.current_scan_info.info.monger_mark=="IM"){
                // так тоже нельзя - надо через find - певрым может быть любой
                state.scan_statistics_local.event[0].count++;
              }

              if ((state.current_scan_info.info.monger_mark=="KSG")||(state.current_scan_info.info.monger_mark=="KST")){
                // так тоже нельзя - надо через find - певрым может быть любой
                state.scan_statistics_local.event[1].count++;
              }

              if (state.current_scan_info.info.monger_mark=="USH"){
                // так тоже нельзя - надо через find - певрым может быть любой
                state.scan_statistics_local.event[2].count++;
              }

              if (state.current_scan_info.info.monger_mark=="UM"){
                // так тоже нельзя - надо через find - певрым может быть любой
                state.scan_statistics_local.event[3].count++;
              }





              base_qr.scanned = true;
            }
            else{
              //console.log('Уже сканировали билет - подсветить его синим!');
            }



            //console.log('Билет правильный');
            bb1 = true;
          }

        });

        if (!bb1){
          //console.log('Спектакль правильный, но билет на найден - сделать прямой запрос в базу - может толькоко что купили?')
        }


      }
      // не сегодня, ищем в загруженной афише - что за название
      else{
        let bb = false;
        //console.log('1');
        state.eventAfishaScan.data.events.forEach(event =>{
          // нашли в афише
          if (event.id == state.current_scan_info.info.event){
            //console.log('in actual');
            bb = true;

            state.current_scan_info.suss.show = event.show_name;
            state.current_scan_info.suss.date = event.timedate;


          }
        })

        // не нашли в афише - можно сразу сказать - билет лесом, а можно сделать чистый запрос к базе
      if (!bb) {
          //console.log('not in actual event - need send direct server que');
        }

      }








    },

    // подгрузить данные для отрисовки зала
    loadEventMapKassa(state, event_id){

      //console.log('1');

      let pr = state.api.loadEvent_Kassa(event_id);

      pr.then( data =>{
        state.eventMapKassa = {
          b:true,
          data: data
        }
      })

      //console.log('2');

    },

    loadMongerList(state){
      //console.log(state);

      state.mongerList.b = false;
      state.mongerList.r = true;

      let pr = state.api.loadMongerList();

      pr.then( data =>{
        state.mongerList = {
          b:true,
          r:false,
          data: data
        }
      })
      //console.log('4');


    },


    // подгрузить данные для загрузки мероприятия на сканер
    loadAfishaScan(state){

      //console.log('1');

      state.eventAfishaScan.b = false;
      state.eventAfishaScan.r = true;

      let pr = state.api.loadAfisha();

      pr.then( data =>{
        state.eventAfishaScan = {
          b:true,
          data: data
        }
      })
      //console.log('3');
    },

    // получить данные по сегодняшнему спектаклю
    loadEventScan(state, event_id){

      //console.log('4');

      state.qr_event_data.b = false;
      state.qr_event_data.r = true;

      let pr = state.api.loadEvent_Scan(event_id);

      pr.then( data =>{
        state.currentEventScan = {
          b:true,
          event: event_id,
          data: data
        };
        //console.log(state.currentEventScan);

        // подготовить ожидаемые билеты - локально
        this.commit('compileLegend');

        // обработать и сформировать массив qr
        this.commit('compileQRData');
        //console.log('99');


      })
      //console.log('5');
    },

    // подготовить ожидания по билетам на сегоняшний спектакль
    compileLegend(state){

      state.scan_statistics_local = {};
      state.scan_statistics_local.event = [];
      state.scan_statistics_local.error = [];
      state.scan_statistics_local.total = {
        order: 100,
        name: "ИТОГО",
        guess: 0,
        mark:  "SUM",
        count: 0,
        color: "red"

      };



      //console.log(state.currentEventScan.data.legend_group);

      // перебор объекта
      //state.currentEventScan.data.legend_group

      for (let key in state.currentEventScan.data.legend_group) {

        let group = state.currentEventScan.data.legend_group[key];

        let group_mark = "none";
        let color = "black";

        if (key==='501'){
          group_mark = "IM";
          color = 'green';
        }
        if (key==='502'){
          group_mark = "KS";
          color = "orange";
        }

        // уполномоченные - их отдельно записать
        if (key==='503'){
          // перебираем их внутри еще
          for (let key_m in group.more) {
            let monger = group.more[key_m];

            let t = {};
            t.order       = 100 + monger.id;    // 501 - 500 = 1 инет, 2 касса, 3 уполномоченные
            t.name        = monger.name;
            t.guess       = monger.count;
            t.mark        = monger.mark;
            t.group_mark  = "MNG";
            t.count       = 0;
            t.color       = "blue";

            state.scan_statistics_local.event.push(t);

            state.scan_statistics_local.total.guess += t.guess;
          }

        }
        else{
          let t = {};
          t.order       = key-500;    // 501 - 500 = 1 инет, 2 касса, 3 уполномоченные
          t.name        = group.name;
          t.guess       = group.count;
          t.mark        = group_mark;
          t.group_mark  = group_mark;
          t.count       = 0;
          t.color       = color;

          state.scan_statistics_local.event.push(t);

          state.scan_statistics_local.total.guess += t.guess;
        }
      }

      //console.log(state.scan_statistics_local);


    },

    // обработать и сформировать массив qr
    compileQRData(state){


      //console.log(state.currentEventScan);

      for (let key in state.currentEventScan.data.seats){

        let seat = state.currentEventScan.data.seats[key];

        if (seat.qr.exist){

          // доставать сюда monger mark из отдельного запроса
          let t = {};
          t.id          = seat.id;
          t.monger      = seat.monger;

          t.payment     = seat.payment;
          t.mo          = seat.mo;

          t.sector      = seat.sector_id;
          t.row         = seat.row;
          t.chair       = seat.chair;

          t.scanned     = false;
          t.scan_time   = "";
          //t.


          t.qr      = "! "+seat.qr.infoline;

          state.qr_event_data.data.push(t)
        }
      }


      state.qr_event_data.event   = state.currentEventScan.data.event.id;
      state.qr_event_data.show    = state.currentEventScan.data.show.name;
      state.qr_event_data.date    = state.currentEventScan.data.event.timedate;
      //state.qr_event_data.age     = state.currentEventScan.data.event.id;
      //state.qr_event_data.time    = state.currentEventScan.data.event.id;



      state.qr_event_data.b = true;
      state.qr_event_data.r = false;

      //console.log(state.qr_event_data);

    }




  },
  actions: {
  },
  modules: {
  }
})
