'use strict';

// module.exports = {
//     className: API_connector,
// }
export default class API_connector{

    constructor(){
        //console.log('api init');
    }

    async loadAfisha(){
        //console.log('loadAfsiha');

        let t = await this.post('/afisha');
        //console.log(t);

        return t.data
    }


    async loadMongerList(){
        //console.log('loadMongerList');

        let t = await this.post('/mongerlist');
        //console.log(t);

        return t.data
    }


    async loadEvent_Kassa(id){

        //console.log(id);

        let t = await this.post('/event/'+id+'/kassa');
        //console.log(t);

        return this.prepareEventData(t.data)
    }


    async addScan(data){

        // let api_base_url = 'http://dev.api.zakaz.cloud';
        let api_base_url = 'https://api.zakaz.cloud';

        let global = {};
        global['key']       = 'DFIOJLKfjs9870sdjkfld';
        global['format']    = 'raw';
        global['action']    = 'add';
        global['module']    = 'scan';

        let json = {};
        json['global'] = global;
        json['data']   = data;

        //console.log(json);

        let jj = JSON.stringify(json);
        //console.log(jj);


        let r = await fetch(api_base_url + '/scan',
            {
                method: "POST",
                body: jj
            })
        //     .then((response) => {
        //     //return response.json();
        // })

        return r.json();

        //return this.prepareEventData(t.data)

    }


    async loadEvent_Scan(id){

        //console.log(id);

        let t = await this.post('/event/'+id+'/scan?version=2');
        //console.log(t);

        return this.prepareEventData(t.data)
    }

    prepareEventData(data){

        // надо бы обработать, но меня устраивает json-format
        //console.log(data);

        return data;

    }


    async post(url, data){

        // let api_base_url = 'http://dev.api.zakaz.cloud';
        let api_base_url = 'https://api.zakaz.cloud';

        let global = {};
        global['key']       = 'DFIOJLKfjs9870sdjkfld';
        global['format']    = 'raw';
        global['action']    = 'get';
        global['module']    = 'event';

        let json = {};
        json['global'] = global;
        json['data']   = data;

        //console.log(json);

        let jj = JSON.stringify(json);
        //console.log(jj);


        let r = await fetch(api_base_url + url,
            {
                method: "POST",
                body: jj
            })
        //     .then((response) => {
        //     //return response.json();
        // })

        return r.json();

    }


}
